
/* ----------------------------------------------------------------

	widgets.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/

.widget {
	position: relative;
	margin-top: 50px;
	&:first-child {
		margin-top: 0;
	}
	& > h4 {
		margin-bottom: 25px;
		font-size: 15px;
		font-weight: 600;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	p {
		line-height: 1.7;
		&:not(.lead) { font-size: 14px; }
	}
}

.sidebar-widgets-wrap {
	.widget {
		padding-top: 50px;
		border-top: 1px solid #EEE;
		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
		& > h4 {
			letter-spacing: 1px;
		}
	}
}

/* Tag Cloud
-----------------------------------------------------------------*/

.tagcloud {
	margin-bottom: -2px;
	a {
		display: block;
		float: left;
		margin-right: 4px;
		margin-bottom: 4px;
		padding: 3px 6px;
		border: 1px solid #E5E5E5;
		font-size: 13px !important;
		color: #666;
		@include border-radius(2px);
		&:hover {
			border-color: $theme-color !important;
			color: $theme-color !important;
		}
	}
}

body {
	&:not(.device-touch) {
		.tagcloud {
			a {
				@include transition(all .3s ease-in-out);
			}
		}
	}
}

/* Widget - Links
-----------------------------------------------------------------*/

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss {
	ul,
	li {
		list-style: none;
		margin: 0;
	}
}

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss {
	li {
		padding: 4px;
	}
}

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss,
.widget_recent_comments {
	li {
		a {
			display: inline-block;
			padding: 0 3px 0 12px;
			background: url("images/icons/widget-link.png") left top no-repeat;
			border: none !important;
			color: #444;
			font-size: 14px;
			&:hover {
				color: #000;
			}
		}
	}
}

.widget_recent_comments {
	li {
		display: block;
		background: url("images/icons/widget-comment.png") left top no-repeat;
		padding: 4px 0 4px 20px;
		a {
			display: inline;
			padding: 0;
			background: none !important;
		}
	}
}

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss {
	 > ul {
		> li {
			&:first-child {
				border-top: 0 !important;
			 }
		 }
	 }
}

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss {
	 > ul {
		margin-top: -4px !important;
	}
}

.widget_nav_menu,
.widget_links,
.widget_meta,
.widget_archive,
.widget_recent_comments,
.widget_recent_entries,
.widget_categories,
.widget_pages,
.widget_rss {
	 ul {
		ul {
			margin-left: 15px;
		}
	}
}


/* Widget - Testimonial & Twitter
-----------------------------------------------------------------*/

.widget {
	.testimonial {
		&.no-image {
			.testi-image {
				display: none;
			}
		}
		&.twitter-scroll {
			.testi-image,
			.testi-image a,
			.testi-image img,
			.testi-image i {
				width: 28px;
				height: 28px;
			}
			.testi-image {
				margin-right: 10px;
				i {
					background-color: #EEE;
					line-height: 28px;
					font-size: 14px;
					color: #888;
				}
			}
		}
		p:not(.lead) {
			font-size: 14px;
		}
		&:not(.twitter-scroll) {
			.testi-image,
			.testi-image a,
			.testi-image img {
				width: 42px;
				height: 42px;
			}
		}
	}
}

/* Widget - Quick Contact Form
-----------------------------------------------------------------*/

.quick-contact-widget {
	.form-control,
	.input-group,
	.sm-form-control {
		margin-bottom: 10px;
	}
	.input-group {
		.form-control {
			margin-bottom: 0;
		}
	}
}

.quick-contact-widget form,
#template-contactform {
	position: relative;
}

/* Newsletter Widget
-----------------------------------------------------------------*/

.subscribe-widget {
	h5 {
		font-weight: 300;
		font-size: 14px;
		line-height: 1.5;
	}
}

/* blockquote
-----------------------------------------------------------------*/
blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17px;
	border-left: 5px solid #EEE;
	.float-left {
		max-width: 300px;
		margin: 5px 20px 10px 0;
		padding-right: 0;
	}
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #EEE;
	border-left: 0;
	text-align: right;
}


/* Twitter Feed Widget
-----------------------------------------------------------------*/

.widget-twitter-feed {
	li {
		margin: 15px 0 0 0;
		&:first-child {
			margin-top: 0;
		}
	}
	small {
		display: block;
		margin-top: 3px;
		a {
			color: #999;
		}
	}
	.twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar { display: none; }

	.twitter-feed.twitter-feed-avatar i.icon-twitter { display: none; }

	.twitter-feed-avatar { margin-left: 44px; }

	.twitter-feed-avatar > li a.twitter-avatar {
		display: block;
		position: absolute;
		left: -44px;
		text-align: center;
		top: 2px;
		width: 32px;
		height: 32px;
		a.twitter-avatar img { border-radius: 50%; }
	}
}

/* Widget Filter Links
-----------------------------------------------------------------*/

.widget-filter-links ul {
	list-style: none;
	margin-bottom: 0;
}

.widget-filter-links .widget-filter-reset {
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	font-size: 12px;
	line-height: 22px;
}

.widget:not(:first-child) .widget-filter-reset { top: 50px; }

.widget-filter-links .widget-filter-reset a { color: red; }

.widget-filter-links .widget-filter-reset.active-filter { display: none; }

.widget-filter-links li {
	position: relative;
	font-size: 15px;
	line-height: 24px;
}

.widget-filter-links li:not(:first-child) { margin-top: 10px; }

.widget-filter-links li a {
	display: block;
	color: #444;
	font-family: $heading-font;
	font-weight: 500;
}

.widget-filter-links li span {
	position: absolute;
	top: 1px;
	left: auto;
	right: 0;
	font-size: 11px;
	width: 22px;
	height: 22px;
	line-height: 22px;
	text-align: center;
	color: #777;
	background-color: #EEE;
	border-radius: 50%;
}

.widget-filter-links li:hover a { color: #777; }

.widget-filter-links li.active-filter a { font-weight: 700; }

.widget-filter-links li.active-filter span {
	color: #FFF;
	background-color: $theme-color;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

/* Navigation Tree
-----------------------------------------------------------------*/

.nav-tree {
	position: relative;
	margin-bottom: 40px;
	 ul {
		margin-bottom: 0;
		list-style: none;
	}
	> ul {
		margin: -8px 0;
	}
	li {
		position: relative;
		a {
			display: block;
			padding: 8px 0;
			color: #333;
			text-transform: uppercase;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: 2px;
			font-family: $heading-font;
		}
		i {
			font-size: 14px;
			width: 16px;
			text-align: center;
			&:not(.icon-angle-down) {
				margin-right: 8px;
				position: relative;
				top: 1px;
			}
			&.icon-angle-down {
				width: auto;
				font-size: 12px;
				margin-left: 2px;
			}
		}
		&:hover,
		&.current,
		&.active {
			> a { color: $theme-color !important; }
		}
	}
	ul ul {
		display: none;
		a {
			font-size: 12px;
			padding: 6px 0;
			letter-spacing: 1px;
			font-family: $body-font;
			i.icon-angle-down { font-size: 12px; }
		}
		a { padding-left: 20px; }
		ul a { padding-left: 40px; }
		ul ul a { padding-left: 60px; }
		ul ul ul a { padding-left: 80px; }
	}
	li.current > ul { display: block; }
}