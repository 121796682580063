
/*-----------------------------------------------------------------------------------

	Shortcodes: countdown.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Countdown
-----------------------------------------------------------------*/

.countdown {
	display: block;
}

.countdown-row {
	display: block;
	position: relative;
	text-align: center;
}

.countdown-section {
	display: inline-block;
	font-size: 11px;
	line-height: 1;
	text-align: center;
	width: 25%;
	border-left: 1px dotted #BBB;
	color: #888;
	text-transform: capitalize;
	&:first-child {
		border-left: 0;
	}
}

.countdown-amount {
	display: block;
	font-size: 20px;
	color: #333;
	margin-bottom: 5px;
}

.countdown-descr {
	display: block;
	width: 100%;
}

/* Countdown - Medium
-----------------------------------------------------------------*/

.countdown-medium {
	.countdown-section {
		font-size: 14px;
	}
	.countdown-amount {
		font-size: 30px;
		margin-bottom: 7px;
	}
}

/* Countdown - Large
-----------------------------------------------------------------*/

.countdown-large {
	.countdown-section {
		font-size: 18px;
	}
	.countdown-amount {
		font-size: 44px;
		font-weight: 700;
		margin-bottom: 8px;
	}
}

/* Countdown - Inline
-----------------------------------------------------------------*/

.countdown {
	&.countdown-inline {
		display: inline-block;
		.countdown-row {
			display: inline-block;
			text-align: center;
		}
		.countdown-section {
			display: inline-block;
			font-size: inherit;
			line-height: inherit;
			width: auto;
			border: none;
			color: inherit;
			margin-left: 7px;
			text-transform: inherit;
			&:first-child {
				margin-left: 0;
			}
		}
		.countdown-amount {
			display: inline-block;
			font-size: inherit;
			color: inherit;
			font-weight: bold;
			margin: 0 3px 0 0;
		}
		.countdown-descr {
			display: inline-block;
			width: auto;
		}
	}
}

/* Countdown - Coming Soon
-----------------------------------------------------------------*/

body {
	&:not(.device-sm):not(.device-xs) {
		.countdown {
			&.countdown-large {
				&.coming-soon {
					.countdown-section {
						border: none;
						padding: 15px;
					}
					.countdown-amount {
						height: 140px;
						line-height: 140px;
						@include border-radius(50%);
						background-color: rgba(0,0,0,0.2);
						margin-bottom: 15px !important;
					}
				}
			}
		}
	}
}