/*-----------------------------------------------------------------------------------

    ARGO SASS
    Version: 2018.1

    * Includes:
    	* Bootstrap v4
    	* Canvas v5.6
    	* Featherlight

-----------------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Roboto');

// Bootstrap
@import 'bootstrap4/bootstrap';

// Canvas
@import 'canvas/canvas';

// Vendor Extras
@import 'extra/animate';
@import 'extra/font-icons';
@import 'extra/spinners';
@import 'extra/swiper';
@import 'extra/featherlight.min.css';
@import '../vendor/summernote/summernote-bs4.css';
@import 'extra/select-boxes';
@import 'extra/bs-switches';
@import 'extra/radio-checkbox';

// Argo
@import 'argo/argo';
